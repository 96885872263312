<template>
  <div class="container">
    <div v-if="books && books.length" class="row">
      <div class="col-md-6 col-xl-4" v-for="libro in books" :key="libro.id">
        <div class="libro text-center text-md-start">
          <img loading="lazy" v-if="libro.portada" class="libro__portada" :src="require('@/assets/libros/' + libro.portada + '')" :alt="` ${libro.titulo} - Georges Simenon`">
          <h2 v-if="libro.titulo" class="libro__titulo">{{ libro.titulo }}</h2>
          <div v-if="libro.descripcion" class="libro__descripcion" v-html="libro.descripcion"></div>
          <div class="libro__info">
            <div v-if="libro.info.traduccion">
              <span class="info-label">TRADUCCIÓN:</span> {{ libro.info.traduccion }}
            </div>
            <div v-if="libro.info.isbn">
              <span class="info-label">ISBN:</span> {{ libro.info.isbn }}
            </div>
            <div v-if="libro.info.pvp">
              <span class="info-label">PVP:</span> {{ libro.info.pvp }}€
            </div>
          </div>
          <div class="libro__enlaces">
            <div v-if="libro.enlaces.anagrama">
              <a :href="libro.enlaces.anagrama" target="_blank" rel="noopener noreferrer">Más información en Anagrama</a>
            </div>
            <div v-if="libro.enlaces.acantilado">
              <a :href="libro.enlaces.acantilado" target="_blank" rel="noopener noreferrer">Más información en Acantilado</a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="row">
      <div class="col text-center">
        No hay libros
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted } from 'vue'
import { useMeta } from 'vue-meta'
import books from '@/data/books.json'
export default {
  name: 'Libros',
  setup () {
    useMeta({
      title: 'Los libros',
      og: {
        title: 'Los libros | Georges Simenon',
        url: window.location.href
      },
      twitter: {
        title: 'Los libros | Georges Simenon'
      }
    })
    onMounted(() => {
      document.body.style.backgroundColor = '#54d174'
      document.body.style.backgroundImage = ''
    })
    return {
      books
    }
  }
}
</script>

<style lang="scss" scoped>
  @import '../sass/vendors/bootstrap';
  .libro {
    margin-bottom: 5rem;
  }
  .libro__portada {
    max-width: 100%;
    max-height: 395px;
    margin-bottom: 3rem;
    box-shadow: 7px 10px 12px rgba(0, 0, 0, 0.3);
  }
  .libro__titulo {
    @include font-size(24px);
    line-height: 1.1;
    margin-bottom: 1.5rem;
    font-family: 'Barlow Condensed', sans-serif;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
  }
  .libro__descripcion {
    line-height: 1.4;
  }
  .libro__info {
    font-size: 14px;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .info-label {
    font-family: 'Barlow Condensed', sans-serif;
    font-size: 12px;
    font-weight: 600;
  }
  .libro__enlaces {
    line-height: 1.2;
    a {
      display: inline-block;
      margin-bottom: 0.5rem;
    }
  }
</style>
